import React from 'react';
import {
 Container, Row, Col, NavbarToggler, Collapse,
 Navbar 
} from 'reactstrap';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import ScrollAnim from 'rc-scroll-anim';
import PrimaryNav from '../PrimaryNav';
import SocialLink from '../SocialLink';

// code syntax-highlighting theme
// feel free to change it to another one
// import 'prismjs/themes/prism-twilight.css'

// main site style
import './index.scss';

const { Element } = ScrollAnim;

class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  changeSection = () => {
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const { children, data, className } = this.props;
    return (
      <StaticQuery query={pageQuery} render={data => (
        <div className={`App ${className}`}>
          <Helmet title={data.site.siteMetadata.title} />
          <div className="hidden">
            <div itemType="http://schema.org/LocalBusiness">
            <a itemProp="url" href="http://jkfilm.vn"><div itemProp="name"><strong>JKFilm Official Website</strong></div>
            </a>
            <div itemProp="description">bring Vietnam’s cinema to the international scene</div>
            <div itemProp="address" itemType="http://schema.org/PostalAddress">
            <span itemProp="streetAddress">483/37 – Le Van Sy</span>
            <span itemProp="addressLocality">Ho Chi Minh</span>
            <span itemProp="addressRegion">Ward 12, District 3</span>
            <span itemProp="postalCode">84</span>
            <span itemProp="addressCountry">Vietnam</span>
            </div>
            </div>
          </div>
          <div itemType="http://schema.org/LocalBusiness"> 
            <header role="banner" className="hidden">
                <h1><a href="http://jkfilm.vn">JKFilm Official Website</a></h1>
                <p className="description">Short film, film passion, reflecting different aspects of current Vietnamese contemporary between city life and country life</p>
            </header>
          </div>
          <Navbar color="light" light expand="lg" className="fixed-top">
            <Link className="navbar-brand" to="/">
              <img src="/files/logo-jkfilm.jpg" alt="Jkfilm official website"/>
            </Link>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <PrimaryNav onChange={this.changeSection} />
            </Collapse>
          </Navbar>
          <div className="pageContent">{children}</div>
          <Element id="sectionContact">
            <footer className="footer">
              <Container>
                <Row>
                  <Col xs="12" sm="4">
                    <div className="mb-3">
                      <p className="text-muted">
                        Address:
                      </p>
                      <div>390/35 CMT8,</div>
                      <div>Ward 11, District 3,</div>
                      <div>Ho Chi Minh city – Vietnam</div>
                    </div>
                  </Col>
                  <Col xs="12" sm="6">
                    <div className="mb-3">
                      <div className="text-muted">
                        Tel:
                      </div>
                      <div className="mb-4"><a className="pr-2" href="tel:+84906647507">(+84) 090 664 7507 </a></div>
                      <div className="text-muted">
                        Email:
                      </div>
                      <div>
                        <a href="mailto:thitran@jkfilm.vn" title="Write Email">thitran@jkfilm.vn</a>
                        {' / '}
                        <a href="mailto:phamthienan@jkfilm.vn" title="Write Email">phamthienan@jkfilm.vn</a>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" sm="2">
                    <div className="mb-3">
                      <SocialLink />
                    </div>
                  </Col>
                </Row>
              </Container>
              <div className="footer-inner">
              © Copyright 2019 JKFilm. <span className="d-block d-sm-none" />   All Rights Reserved.
              </div>
            </footer>
          </Element>
        </div>
      )} />
    );
  }
}

TemplateWrapper.propTypes = {
  children: PropTypes.any,
};

const pageQuery = graphql`
  query LayoutIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default TemplateWrapper;
