import React from 'react';
import GatsbyLink from 'gatsby-link';
import ScrollAnim from 'rc-scroll-anim';
import PropTypes from 'prop-types';
import {
  Nav,
} from 'reactstrap';

const { Link } = ScrollAnim;

class PrimaryNav extends React.Component {
  render() {
    return (
      <Nav className="ml-auto" navbar>
        <li className="nav-item">
          <GatsbyLink className="nav-link" activeClassName="active" to="/">Home</GatsbyLink>
        </li>
        <Link
          to="sectionAboutUs"
          offsetTop={73}
          className="nav-item home-nav"
          component="li"
          onFocus={this.props.onChange}
        >
          <span className="nav-link">
          About Us
          </span>
        </Link>
        <GatsbyLink className="nav-link" activeClassName="active" to="/members">Members</GatsbyLink>
        <Link
          to="sectionFeatureFilm"
          offsetTop={73}
          className="nav-item home-nav"
          component="li"
          onFocus={this.props.onChange}
        >
          <span className="nav-link">
          Feature Film
          </span>
        </Link>
        <Link
          to="sectionShortFilms"
          offsetTop={73}
          className="nav-item home-nav"
          component="li"
          onFocus={this.props.onChange}
        >
          <span className="nav-link">
          Short Films
          </span>
        </Link>
        <Link
          to="sectionContact"
          offsetTop={73}
          className="nav-item"
          component="li"
          onFocus={this.props.onChange}
        >
          <span className="nav-link">
          Contact
          </span>
        </Link>
      </Nav>
    );
  }
}

PrimaryNav.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PrimaryNav;
